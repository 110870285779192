import React, { useEffect, useRef, useLayoutEffect, useContext, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom"
//transitionContext
import TransitionContext from '../../../context/transitionContext';
//components
import Footer from '../../../components/footer.react';
import CTA from '../../../components/cta.react';
//styles
import '../../../styles/project.css';

const Project = ({ project }) => {
    //refs
    const projectContainer = useRef(HTMLElement);
    const projectBanner = useRef(HTMLElement);
    const projectInfoContainer = useRef(HTMLElement);
    const projectInfoSpacer = useRef(HTMLElement);
    const projectInfoDetails = useRef(HTMLElement);
    const projectContentContainer = useRef(HTMLElement);
    const projectCTA = useRef(HTMLElement);
    //state
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);
    //context
    const { exit } = useContext(TransitionContext);
    //plugins
    gsap.registerPlugin(ScrollTrigger);

    //intro animation timeline 
    const getIntroTL = () => {
        const tl = gsap.timeline();
        tl.from(projectBanner.current, {
            duration: 0.8,
            y: 100,
            opacity: 0,
            ease: 'ease',
        }, 0)
        tl.from(projectInfoContainer.current, {
            duration: 0.8,
            delay: 0.1,
            y: 100,
            opacity: 0,
            ease: 'ease',
            onComplete: () => { ScrollTrigger.refresh() }
        }, 0)
        tl.from(projectContentContainer.current, {
            duration: 0.8,
            delay: 0.2,
            y: 100,
            opacity: 0,
            ease: 'ease',
        }, 0)
        return tl;
    }

    //outro animation timeline 
    const getOutroTL = () => {
        const tl = gsap.timeline();
        tl.to(projectBanner.current, {
            duration: 0.8,
            delay: 0.2,
            y: 100,
            opacity: 0,
            ease: 'ease',
        }, 0)
        tl.to(projectInfoContainer.current, {
            duration: 0.8,
            delay: 0.1,
            y: 100,
            opacity: 0,
            ease: 'ease',
        }, 0)
        tl.to(projectContentContainer.current, {
            duration: 0.8,
            y: 100,
            opacity: 0,
            ease: 'ease',
        }, 0)
        tl.to(projectCTA.current, {
            duration: 0.8,
            y: 100,
            opacity: 0,
            ease: 'ease',
        }, 0)
        return tl;
    }

    //activate animation
    const getScrollTL = () => {
        if (!isDesktop) return
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: projectInfoContainer.current,
                markers: false,
                pin: false, // pin the trigger element while active
                start: 'top 300px',
                end: "top 120px",
                scrub: 1,
            }
        });
        tl.addLabel('start')
        tl.to(projectInfoSpacer.current, {
            width: "66.6%",
            ease: "ease",
        }, 0)
        tl.to(projectInfoDetails.current, {
            width: "16.6%",
            ease: "ease",
        }, 0)
        return tl;
    }

    //info animation 
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            getScrollTL();
            getIntroTL()
        })
        return () => {
            ctx.revert();
        };
    }, [isDesktop])

    //start at top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //handle resize
    const handleResize = () => {
        setIsDesktop(window.innerWidth > 600);
    };

    //check window
    useEffect(() => {
        console.log(isDesktop)
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [isDesktop]);

    //outro 
    useEffect(() => {
        const ctx = gsap.context(() => { })
        if (exit) {
            ctx.add(() => { getOutroTL() })
        }
        return () => {
            ctx.revert();
        };
    }, [exit])

    return (
        <div className='project'>
            <div ref={projectContainer} className='project-container'>
                <div ref={projectBanner} className='project-banner'>
                    <img className='project-banner-media' key={project.banner.key} src={project.banner.src} loading="eager" />
                </div>
                <div ref={projectInfoContainer} className='project-info-container'>
                    <div className='project-info-title'>
                        <div>
                            <h4>
                                PR.{project.number} / 016
                            </h4>
                            <h2>
                                <b>{project.name}</b>
                            </h2>
                        </div>
                        <Link className='project-info-title-back' to={'/showcase'}>
                            <div className='project-info-title-back-icon'>
                                <div className="project-info-title-back-icon-vector">
                                    <svg height={20} width={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.03906 1.05957H18.9378V18.9571" stroke="#ECECEC" strokeWidth="2" strokeMiterlimit="10" />
                                        <path d="M1.03906 18.9571L18.9378 1.05957" stroke="#ECECEC" strokeWidth="2" strokeMiterlimit="10" />
                                    </svg>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div ref={projectInfoSpacer} className='project-info-spacer' />
                    <div ref={projectInfoDetails} className='project-info-details'>
                        <div className='project-info-details-scope'>
                            <h4>
                                Scope
                            </h4>
                            <ul>
                                {project.purview.map((purviewItem) =>
                                    <li key={purviewItem}><p>{purviewItem}</p></li>)
                                }
                            </ul>
                        </div>
                        <div className='project-info-details-company'>
                            <h4>
                                Company
                            </h4>
                            <p>{project.company}</p>
                        </div>
                    </div>

                </div>
                <div ref={projectContentContainer} className='project-content-container'>
                    <div className='project-content'>
                        {project.content}
                    </div>
                </div>
            </div>
            <div ref={projectCTA} className='project-cta-container'>
                <CTA />
            </div>
            <Footer instruction={"Scroll Down"} scroll={true} />
        </div >
    );
}

export default Project;
